/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiPushnotificationsCountGet } from '../fn/push-notification/api-pushnotifications-count-get';
import { ApiPushnotificationsCountGet$Params } from '../fn/push-notification/api-pushnotifications-count-get';
import { apiPushnotificationsGet } from '../fn/push-notification/api-pushnotifications-get';
import { ApiPushnotificationsGet$Params } from '../fn/push-notification/api-pushnotifications-get';
import { apiPushnotificationsReadPut } from '../fn/push-notification/api-pushnotifications-read-put';
import { ApiPushnotificationsReadPut$Params } from '../fn/push-notification/api-pushnotifications-read-put';
import { apiPushnotificationsReceivedPut } from '../fn/push-notification/api-pushnotifications-received-put';
import { ApiPushnotificationsReceivedPut$Params } from '../fn/push-notification/api-pushnotifications-received-put';
import { DefaultMultiRecordReturnDto } from '../models/default-multi-record-return-dto';
import { DefaultSingleRecordReturnDto } from '../models/default-single-record-return-dto';

@Injectable({ providedIn: 'root' })
export class PushNotificationService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /** Path part for operation `apiPushnotificationsGet()` */
    static readonly ApiPushnotificationsGetPath = '/api/pushnotifications';

    /**
     * Get all push notifications.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiPushnotificationsGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiPushnotificationsGet$Response(
        params?: ApiPushnotificationsGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<DefaultMultiRecordReturnDto>> {
        return apiPushnotificationsGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Get all push notifications.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiPushnotificationsGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiPushnotificationsGet(params?: ApiPushnotificationsGet$Params, context?: HttpContext): Observable<DefaultMultiRecordReturnDto> {
        return this.apiPushnotificationsGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<DefaultMultiRecordReturnDto>): DefaultMultiRecordReturnDto => r.body)
        );
    }

    /** Path part for operation `apiPushnotificationsCountGet()` */
    static readonly ApiPushnotificationsCountGetPath = '/api/pushnotifications/count';

    /**
     * Get the count of push notifications.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiPushnotificationsCountGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiPushnotificationsCountGet$Response(
        params?: ApiPushnotificationsCountGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<DefaultSingleRecordReturnDto>> {
        return apiPushnotificationsCountGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Get the count of push notifications.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiPushnotificationsCountGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiPushnotificationsCountGet(
        params?: ApiPushnotificationsCountGet$Params,
        context?: HttpContext
    ): Observable<DefaultSingleRecordReturnDto> {
        return this.apiPushnotificationsCountGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<DefaultSingleRecordReturnDto>): DefaultSingleRecordReturnDto => r.body)
        );
    }

    /** Path part for operation `apiPushnotificationsReceivedPut()` */
    static readonly ApiPushnotificationsReceivedPutPath = '/api/pushnotifications/received';

    /**
     * Set the push notification as received.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiPushnotificationsReceivedPut()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiPushnotificationsReceivedPut$Response(
        params: ApiPushnotificationsReceivedPut$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<void>> {
        return apiPushnotificationsReceivedPut(this.http, this.rootUrl, params, context);
    }

    /**
     * Set the push notification as received.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiPushnotificationsReceivedPut$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiPushnotificationsReceivedPut(params: ApiPushnotificationsReceivedPut$Params, context?: HttpContext): Observable<void> {
        return this.apiPushnotificationsReceivedPut$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
    }

    /** Path part for operation `apiPushnotificationsReadPut()` */
    static readonly ApiPushnotificationsReadPutPath = '/api/pushnotifications/read';

    /**
     * Set the push notification as read.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiPushnotificationsReadPut()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiPushnotificationsReadPut$Response(
        params: ApiPushnotificationsReadPut$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<void>> {
        return apiPushnotificationsReadPut(this.http, this.rootUrl, params, context);
    }

    /**
     * Set the push notification as read.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiPushnotificationsReadPut$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiPushnotificationsReadPut(params: ApiPushnotificationsReadPut$Params, context?: HttpContext): Observable<void> {
        return this.apiPushnotificationsReadPut$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
    }
}
