/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SetPushNotificationReadDto } from '../../models/set-push-notification-read-dto';

export interface ApiPushnotificationsReadPut$Params {
    /**
     * Data to update the push notification
     */
    body: SetPushNotificationReadDto;
}

export function apiPushnotificationsReadPut(
    http: HttpClient,
    rootUrl: string,
    params: ApiPushnotificationsReadPut$Params,
    context?: HttpContext
): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(rootUrl, apiPushnotificationsReadPut.PATH, 'put');
    if (params) {
        rb.body(params.body, 'application/json');
    }

    return http.request(rb.build({ responseType: 'text', accept: '*/*', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
    );
}

apiPushnotificationsReadPut.PATH = '/api/pushnotifications/read';
