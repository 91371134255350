/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiEventsCountGet } from '../fn/events/api-events-count-get';
import { ApiEventsCountGet$Params } from '../fn/events/api-events-count-get';
import { apiEventsGet } from '../fn/events/api-events-get';
import { ApiEventsGet$Params } from '../fn/events/api-events-get';
import { apiEventsIdDelete } from '../fn/events/api-events-id-delete';
import { ApiEventsIdDelete$Params } from '../fn/events/api-events-id-delete';
import { apiEventsIdPatch } from '../fn/events/api-events-id-patch';
import { ApiEventsIdPatch$Params } from '../fn/events/api-events-id-patch';
import { apiEventsPost } from '../fn/events/api-events-post';
import { ApiEventsPost$Params } from '../fn/events/api-events-post';
import { DefaultMultiRecordReturnDto } from '../models/default-multi-record-return-dto';
import { DefaultSingleRecordReturnDto } from '../models/default-single-record-return-dto';

@Injectable({ providedIn: 'root' })
export class EventsService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /** Path part for operation `apiEventsGet()` */
    static readonly ApiEventsGetPath = '/api/events';

    /**
     * Get all events.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiEventsGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiEventsGet$Response(
        params?: ApiEventsGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<DefaultMultiRecordReturnDto>> {
        return apiEventsGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Get all events.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiEventsGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiEventsGet(params?: ApiEventsGet$Params, context?: HttpContext): Observable<DefaultMultiRecordReturnDto> {
        return this.apiEventsGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<DefaultMultiRecordReturnDto>): DefaultMultiRecordReturnDto => r.body)
        );
    }

    /** Path part for operation `apiEventsPost()` */
    static readonly ApiEventsPostPath = '/api/events';

    /**
     * Create a new event.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiEventsPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiEventsPost$Response(
        params: ApiEventsPost$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<DefaultSingleRecordReturnDto>> {
        return apiEventsPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Create a new event.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiEventsPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiEventsPost(params: ApiEventsPost$Params, context?: HttpContext): Observable<DefaultSingleRecordReturnDto> {
        return this.apiEventsPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<DefaultSingleRecordReturnDto>): DefaultSingleRecordReturnDto => r.body)
        );
    }

    /** Path part for operation `apiEventsCountGet()` */
    static readonly ApiEventsCountGetPath = '/api/events/count';

    /**
     * Get the count of events.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiEventsCountGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiEventsCountGet$Response(
        params?: ApiEventsCountGet$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<DefaultSingleRecordReturnDto>> {
        return apiEventsCountGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Get the count of events.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiEventsCountGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiEventsCountGet(params?: ApiEventsCountGet$Params, context?: HttpContext): Observable<DefaultSingleRecordReturnDto> {
        return this.apiEventsCountGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<DefaultSingleRecordReturnDto>): DefaultSingleRecordReturnDto => r.body)
        );
    }

    /** Path part for operation `apiEventsIdDelete()` */
    static readonly ApiEventsIdDeletePath = '/api/events/{id}';

    /**
     * Delete a event by id.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiEventsIdDelete()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiEventsIdDelete$Response(params: ApiEventsIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
        return apiEventsIdDelete(this.http, this.rootUrl, params, context);
    }

    /**
     * Delete a event by id.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiEventsIdDelete$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiEventsIdDelete(params: ApiEventsIdDelete$Params, context?: HttpContext): Observable<void> {
        return this.apiEventsIdDelete$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
    }

    /** Path part for operation `apiEventsIdPatch()` */
    static readonly ApiEventsIdPatchPath = '/api/events/{id}';

    /**
     * Update a event by id.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiEventsIdPatch()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiEventsIdPatch$Response(
        params: ApiEventsIdPatch$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<DefaultSingleRecordReturnDto>> {
        return apiEventsIdPatch(this.http, this.rootUrl, params, context);
    }

    /**
     * Update a event by id.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiEventsIdPatch$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiEventsIdPatch(params: ApiEventsIdPatch$Params, context?: HttpContext): Observable<DefaultSingleRecordReturnDto> {
        return this.apiEventsIdPatch$Response(params, context).pipe(
            map((r: StrictHttpResponse<DefaultSingleRecordReturnDto>): DefaultSingleRecordReturnDto => r.body)
        );
    }
}
